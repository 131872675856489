import * as React from "react";
import { useUnmountEffect, useCallbackRef } from "@chakra-ui/react";
import { useSound } from "use-sound";
import type { HowlListeners } from "howler";
import type { HookOptions, SpriteMap } from "use-sound";

import { pauseAllSounds } from "../../../lib/howler";

export interface UseExtendedSoundProps {
  audioUrl: string;
  sprite?: SpriteMap;
}

export const useExtendedSound = (props: UseExtendedSoundProps) => {
  const { audioUrl, sprite = {} } = props;
  const spriteId = Object.keys(sprite)?.[0];
  const [isLoading, setIsLoading] = React.useState(true);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const handleOnLoad = () => setIsLoading(false);

  const handleOnError = () => {
    setIsError(true);
    setIsLoading(false);
  };

  const setPlayingOn = () => setIsPlaying(true);

  const setPlayingOff = () => setIsPlaying(false);

  const [playSound, { stop }] = useSound<HookOptions<HowlListeners>>(audioUrl, {
    onload: handleOnLoad,
    onplay: setPlayingOn,
    onend: setPlayingOff,
    onstop: setPlayingOff,
    onpause: setPlayingOff,
    onloaderror: handleOnError,
    sprite,
  });

  // store a ref to stop callback function
  const stopCallbackRef = useCallbackRef(stop);

  /**
   * In order to play a sound we do three things fist.
   * 1. pause all currently playing sounds
   * 2. stop the current sound to reset the seeking position back to 0
   * 3. play the current sound
   */
  const handlePlaySound = () => {
    pauseAllSounds();
    stop();
    playSound({ id: spriteId });
  };

  // Stop current sound if playing
  const handleStopSound = () => {
    if (isPlaying) {
      stopCallbackRef();
    }
  };

  // stop the current sound when the hook unmounts
  useUnmountEffect(() => {
    stopCallbackRef();
  }, []);

  return {
    isLoading,
    isPlaying,
    isError,
    handlePlaySound,
    handleStopSound,
  } as const;
};

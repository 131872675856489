import CMS from "netlify-cms-app";
import type { CmsMediaLibrary } from "netlify-cms-core";

import "./netlify-identity";

import { NotionPreview } from "./notion-preview";
import { IdControl, IdPreview, ContentUploadLink } from "./widgets";
import config from "./config";
import { MDXWidgetControl } from "./widgets/mdx/control";

// Init the cms
CMS.init({ config });

// Register id widget
CMS.registerWidget("id", IdControl, IdPreview);

// Register link to content upload page
CMS.registerWidget(
  "content-upload-link",
  ContentUploadLink.Control,
  ContentUploadLink.Preview,
);

// Register disabled media library (this used for disabling file upload on the editor)
CMS.registerMediaLibrary({
  name: "disabled",
  init: () => ({ show: () => null, enableStandalone: () => false }),
} as CmsMediaLibrary);

// Register mdx widget for supporting mdx file type
CMS.registerWidget("mdx", MDXWidgetControl, NotionPreview);

import * as React from "react";
import { useCallbackRef, useUnmountEffect } from "@chakra-ui/react";
import useSound from "use-sound";
import { Howl } from "howler";
import ceil from "lodash/ceil";

import { pauseAllSounds } from "../../../lib/howler";

type seekHandlerFn = () => void;
type seekHandlerFnWithValue = (value: number) => void;

export interface UseSoundPlayerProps {
  /**
   * Audio source
   */
  src: string;
  /**
   *Fires when the sound begins playing.
   */
  onPlay?: () => void;
  /**
   * Fires when the sound paused playing.
   */
  onPause?: () => void;
  /**
   * Fires when the sound ends .
   */
  onEnd?: () => void;
}

export type UseSoundPlayerReturn = ReturnType<typeof useSoundPlayer>;

/**
 * A react hook that extends use-sound hook features.
 */
export const useSoundPlayer = (props: UseSoundPlayerProps) => {
  // Props destructuring
  const {
    src,
    onPlay: onPlayProp,
    onPause: onPauseProp,
    onEnd: onEndProps,
  } = props;

  // State
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSeeking, setIsSeeking] = React.useState(false);
  const [seek, setSeek] = React.useState(0);

  // Refs
  const frameRef = React.useRef<number>(null!);

  // use-sound hook that wraps Howler as a react hook
  const [play, { sound, duration, pause, stop }] = useSound(src, {
    onload: handleOnLoad,
    onplay: handleOnPlay,
    onend: handleOnEnd,
    onpause: setPlayingOff,
    onstop: setPlayingOff,
  });

  // store a ref to stop callback function
  const stopCallbackRef = useCallbackRef(stop);

  // Handle sound load
  function handleOnLoad() {
    setIsLoading(false);
  }

  // Handle sound play
  function handleOnPlay() {
    setIsPlaying(true);
    onPlayProp?.();
  }

  // Handle sound end
  function handleOnEnd(this: Howl) {
    this.stop();
    setSeek(0);
    setIsPlaying(false);
    onEndProps?.();
    clearRequestAnimationFrame();
  }

  // Handle sound pause
  function setPlayingOff() {
    setIsPlaying(false);
    onPauseProp?.();
  }

  // Toggle play and pause
  const toggle = React.useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      // Before playing the sound pause all other sounds
      pauseAllSounds();
      play();
    }
  }, [isPlaying, pause, play]);

  /**
   * onSeekStart, onSeekEnd, and onSeek are helpers
   * for making the integration with Chakra Slider easy
   */
  const onSeekStart: seekHandlerFn = React.useCallback(() => {
    setIsSeeking(true);
  }, []);

  const onSeekEnd: seekHandlerFn = React.useCallback(() => {
    setIsSeeking(false);
    sound?.seek(seek);
  }, [seek, sound]);

  const onSeek: seekHandlerFnWithValue = React.useCallback((value) => {
    setSeek(value);
  }, []);

  // A workaround for getting seek value from howl
  const syncSeeking = () => {
    setSeek(Number(sound?.seek()));
    if (isPlaying && !isSeeking) {
      frameRef.current = requestAnimationFrame(syncSeeking);
    }
  };

  // Cancel raf
  const clearRequestAnimationFrame = () => {
    if (frameRef.current) {
      cancelAnimationFrame(frameRef.current);
    }
  };

  // Run the syncSeeking effect
  React.useEffect(() => {
    if (isSeeking) {
      clearRequestAnimationFrame();
    }
    if (isPlaying && !isSeeking) {
      frameRef.current = requestAnimationFrame(syncSeeking);
    }
    return clearRequestAnimationFrame;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, isSeeking]);

  // stop the current sound when the hook unmounts
  useUnmountEffect(() => {
    stopCallbackRef();
  }, []);

  return {
    /**
     * Convert duration from ms to seconds
     * rounded up to precision
     */
    duration: ceil((duration ?? 0.01) / 1000, 2),
    seek: { value: seek, onSeekStart, onSeekEnd, onSeek },
    state: { isPlaying, isLoading, isSeeking },
    play,
    pause,
    toggle,
    stop,
  };
};

import * as React from "react";
import styled from "styled-components/macro";
import { nanoid } from "nanoid";

import { CopyButton } from "web-app/components/studio-elements/buttons/copy-button";

export interface IdWidgetProps {
  value: string;
  classNameWrapper: string;
  onChange: (value: string) => void;
}

// Widget controller
export const IdControl: React.FC<IdWidgetProps> = ({
  value,
  classNameWrapper,
  onChange,
}) => {
  const [id, setId] = React.useState<string>(
    () => value ?? `notion-${nanoid(25).trim().toLowerCase()}`,
  );

  // Sync input value
  React.useEffect(() => {
    onChange(id);
  }, [onChange, id]);

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value.trim().toLowerCase());
  };

  return (
    <InputWrapper>
      <input
        type="text"
        disabled
        value={id}
        onChange={handleChange}
        className={classNameWrapper}
      />
      <CopyButton value={id} />
    </InputWrapper>
  );
};

// Widget preview (Hide preview on the preview pan)
export const IdPreview: React.FC<Pick<IdWidgetProps, "value">> = () => {
  return null;
};

const InputWrapper = styled.div`
  position: relative;
  > button {
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translate(-50%, -50%);
    color: hsl(220deg 56% 50%);
    background-color: hsl(205deg 92% 95%);
  }
`;

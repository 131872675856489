import * as React from "react";

import { useUserSettings } from "web-app/stores/user-settings";

import { CONFIG } from "../constants";

export const useDefaultUserSettings = () => {
  const [, set] = useUserSettings();

  // Default user settings in CMS editor
  React.useEffect(() => {
    set(CONFIG.settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

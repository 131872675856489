import * as React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export interface LogoIconProps extends IconProps {
  title?: string;
}

export const LogoIcon = function LogoIcon({
  ref,
  ...props
}: LogoIconProps & { ref?: React.RefObject<SVGSVGElement | null> }) {
  const { title = "Natanada", ...iconProps } = props;

  return (
    <Icon ref={ref} viewBox="0 0 726 150" {...iconProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13C0 5.82029 5.8203 0 13 0H137C144.18 0 150 5.8203 150 13V137C150 144.18 144.18 150 137 150H13C5.82029 150 0 144.18 0 137V13ZM100 25H81V125H94C97.3137 125 100 122.314 100 119V84H119C122.314 84 125 81.3137 125 78V65H100V25ZM50 100C63.8071 100 75 88.8071 75 75C75 61.1929 63.8071 50 50 50C36.1929 50 25 61.1929 25 75C25 88.8071 36.1929 100 50 100ZM50 81.425C46.4516 81.425 43.575 78.5484 43.575 75C43.575 71.4516 46.4516 68.575 50 68.575C53.5484 68.575 56.425 71.4516 56.425 75C56.425 78.5484 53.5484 81.425 50 81.425Z"
      />
      <path d="M238.606 40.9859V101.62C238.606 106.549 236.141 109.014 231.211 109.014H223.028C220.991 109.014 219.413 108.554 218.296 107.634C217.244 106.714 216.061 105.103 214.746 102.803L199.958 79.4366C198.315 76.4789 196.803 72.6009 195.423 67.8028H194.732C195.39 72.4038 195.718 76.5446 195.718 80.2254V109.014H176V48.3803C176 43.4507 178.465 40.9859 183.394 40.9859H191.577C193.615 40.9859 195.16 41.446 196.211 42.3662C197.263 43.2864 198.479 44.8967 199.859 47.1972L214.056 69.4789C215.831 72.6338 217.573 76.5117 219.282 81.1127H219.972C219.38 76.2488 219.052 72.1408 218.986 68.7887V40.9859H238.606Z" />
      <path d="M289.605 94.3239H270.971L267.521 109.014H245.732L263.38 46.507C263.84 44.8639 264.727 43.5493 266.042 42.5634C267.356 41.5117 268.868 40.9859 270.577 40.9859H290C291.708 40.9859 293.22 41.5117 294.535 42.5634C295.849 43.5493 296.737 44.8639 297.197 46.507L314.845 109.014H293.056L289.605 94.3239ZM286.154 79.338L282.802 64.7465C282.211 61.6573 281.816 59.061 281.619 56.9578H278.957C278.563 60.0469 278.169 62.6432 277.774 64.7465L274.422 79.338H286.154Z" />
      <path d="M371.633 56.9578H352.605V109.014H331.211V56.9578H312.183V40.9859H371.633V56.9578Z" />
      <path d="M412.887 94.3239H394.253L390.802 109.014H369.014L386.662 46.507C387.122 44.8639 388.009 43.5493 389.324 42.5634C390.638 41.5117 392.15 40.9859 393.859 40.9859H413.281C414.99 40.9859 416.502 41.5117 417.817 42.5634C419.131 43.5493 420.018 44.8639 420.479 46.507L438.126 109.014H416.338L412.887 94.3239ZM409.436 79.338L406.084 64.7465C405.493 61.6573 405.098 59.061 404.901 56.9578H402.239C401.845 60.0469 401.45 62.6432 401.056 64.7465L397.704 79.338H409.436Z" />
      <path d="M507.892 40.9859V101.62C507.892 106.549 505.427 109.014 500.497 109.014H492.314C490.276 109.014 488.699 108.554 487.582 107.634C486.53 106.714 485.347 105.103 484.032 102.803L469.244 79.4366C467.6 76.4789 466.089 72.6009 464.708 67.8028H464.018C464.676 72.4038 465.004 76.5446 465.004 80.2254V109.014H445.286V48.3803C445.286 43.4507 447.751 40.9859 452.68 40.9859H460.863C462.901 40.9859 464.445 41.446 465.497 42.3662C466.549 43.2864 467.765 44.8967 469.145 47.1972L483.342 69.4789C485.117 72.6338 486.859 76.5117 488.568 81.1127H489.258C488.666 76.2488 488.338 72.1408 488.272 68.7887V40.9859H507.892Z" />
      <path d="M558.891 94.3239H540.257L536.807 109.014H515.018L532.666 46.507C533.126 44.8639 534.013 43.5493 535.328 42.5634C536.642 41.5117 538.154 40.9859 539.863 40.9859H559.285C560.994 40.9859 562.506 41.5117 563.821 42.5634C565.135 43.5493 566.022 44.8639 566.483 46.507L584.13 109.014H562.342L558.891 94.3239ZM555.44 79.338L552.088 64.7465C551.497 61.6573 551.102 59.061 550.905 56.9578H548.243C547.849 60.0469 547.454 62.6432 547.06 64.7465L543.708 79.338H555.44Z" />
      <path d="M591.29 109.014V40.9859C602.858 40.3286 612.553 40 620.374 40C628.459 40 634.703 41.0188 639.107 43.0563C643.576 45.0282 646.863 48.5446 648.966 53.6056C651.069 58.6009 652.121 65.7324 652.121 75C652.121 84.2676 651.069 91.4319 648.966 96.493C646.863 101.488 643.576 105.005 639.107 107.042C634.703 109.014 628.459 110 620.374 110C610.515 110 600.82 109.671 591.29 109.014ZM612.684 94.4225L620.374 94.5211C624.055 94.5211 626.487 93.2394 627.67 90.6761C628.919 88.0469 629.543 82.8216 629.543 75C629.543 67.1784 628.919 61.9859 627.67 59.4225C626.487 56.7934 624.055 55.4789 620.374 55.4789H612.684V94.4225Z" />
      <path d="M700.466 94.3239H681.832L678.382 109.014H656.593L674.241 46.507C674.701 44.8639 675.588 43.5493 676.903 42.5634C678.217 41.5117 679.729 40.9859 681.438 40.9859H700.861C702.569 40.9859 704.081 41.5117 705.396 42.5634C706.71 43.5493 707.598 44.8639 708.058 46.507L725.706 109.014H703.917L700.466 94.3239ZM697.015 79.338L693.663 64.7465C693.072 61.6573 692.677 59.061 692.48 56.9578H689.818C689.424 60.0469 689.03 62.6432 688.635 64.7465L685.283 79.338H697.015Z" />
    </Icon>
  );
};

export const LogoMarkIcon = function LogoMarkIcon({
  ref,
  ...props
}: LogoIconProps & { ref?: React.RefObject<SVGSVGElement | null> }) {
  const { title = "Natanada", ...iconProps } = props;

  return (
    <Icon ref={ref} viewBox="0 0 150 150" {...iconProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13C0 5.82029 5.8203 0 13 0H137C144.18 0 150 5.8203 150 13V137C150 144.18 144.18 150 137 150H13C5.82029 150 0 144.18 0 137V13ZM100 25H81V125H94C97.3137 125 100 122.314 100 119V84H119C122.314 84 125 81.3137 125 78V65H100V25ZM50 100C63.8071 100 75 88.8071 75 75C75 61.1929 63.8071 50 50 50C36.1929 50 25 61.1929 25 75C25 88.8071 36.1929 100 50 100ZM50 81.425C46.4516 81.425 43.575 78.5484 43.575 75C43.575 71.4516 46.4516 68.575 50 68.575C53.5484 68.575 56.425 71.4516 56.425 75C56.425 78.5484 53.5484 81.425 50 81.425Z"
      />
    </Icon>
  );
};

export const LogoWordMarkIcon = function LogoWordIcon({
  ref,
  ...props
}: LogoIconProps & { ref?: React.RefObject<SVGSVGElement | null> }) {
  const { title = "Natanada", ...iconProps } = props;

  return (
    <Icon ref={ref} viewBox="0 0 550 150" {...iconProps}>
      <title>{title}</title>
      <path d="M62.6056 40.9859V101.62C62.6056 106.549 60.1408 109.014 55.2113 109.014H47.0282C44.9906 109.014 43.4131 108.554 42.2958 107.634C41.2441 106.714 40.061 105.103 38.7465 102.803L23.9577 79.4366C22.3146 76.4789 20.8028 72.6009 19.4225 67.8028H18.7324C19.3897 72.4038 19.7183 76.5446 19.7183 80.2254V109.014H0V48.3803C0 43.4507 2.46479 40.9859 7.39437 40.9859H15.5775C17.615 40.9859 19.1596 41.446 20.2113 42.3662C21.2629 43.2864 22.4789 44.8967 23.8592 47.1972L38.0563 69.4789C39.831 72.6338 41.5728 76.5117 43.2817 81.1127H43.9718C43.3803 76.2488 43.0516 72.1408 42.9859 68.7887V40.9859H62.6056Z" />
      <path d="M113.605 94.3239H94.9714L91.5207 109.014H69.7319L87.3798 46.507C87.8399 44.8639 88.7273 43.5493 90.0418 42.5634C91.3564 41.5117 92.8681 40.9859 94.577 40.9859H114C115.708 40.9859 117.22 41.5117 118.535 42.5634C119.849 43.5493 120.737 44.8639 121.197 46.507L138.845 109.014H117.056L113.605 94.3239ZM110.154 79.338L106.802 64.7465C106.211 61.6573 105.816 59.061 105.619 56.9578H102.957C102.563 60.0469 102.169 62.6432 101.774 64.7465L98.4221 79.338H110.154Z" />
      <path d="M195.633 56.9578H176.605V109.014H155.211V56.9578H136.183V40.9859H195.633V56.9578Z" />
      <path d="M236.887 94.3239H218.253L214.802 109.014H193.014L210.662 46.507C211.122 44.8639 212.009 43.5493 213.324 42.5634C214.638 41.5117 216.15 40.9859 217.859 40.9859H237.281C238.99 40.9859 240.502 41.5117 241.817 42.5634C243.131 43.5493 244.018 44.8639 244.479 46.507L262.126 109.014H240.338L236.887 94.3239ZM233.436 79.338L230.084 64.7465C229.493 61.6573 229.098 59.061 228.901 56.9578H226.239C225.845 60.0469 225.45 62.6432 225.056 64.7465L221.704 79.338H233.436Z" />
      <path d="M331.892 40.9859V101.62C331.892 106.549 329.427 109.014 324.497 109.014H316.314C314.276 109.014 312.699 108.554 311.582 107.634C310.53 106.714 309.347 105.103 308.032 102.803L293.244 79.4366C291.6 76.4789 290.089 72.6009 288.708 67.8028H288.018C288.676 72.4038 289.004 76.5446 289.004 80.2254V109.014H269.286V48.3803C269.286 43.4507 271.751 40.9859 276.68 40.9859H284.863C286.901 40.9859 288.445 41.446 289.497 42.3662C290.549 43.2864 291.765 44.8967 293.145 47.1972L307.342 69.4789C309.117 72.6338 310.859 76.5117 312.568 81.1127H313.258C312.666 76.2488 312.338 72.1408 312.272 68.7887V40.9859H331.892Z" />
      <path d="M382.891 94.3239H364.257L360.807 109.014H339.018L356.666 46.507C357.126 44.8639 358.013 43.5493 359.328 42.5634C360.642 41.5117 362.154 40.9859 363.863 40.9859H383.285C384.994 40.9859 386.506 41.5117 387.821 42.5634C389.135 43.5493 390.022 44.8639 390.483 46.507L408.13 109.014H386.342L382.891 94.3239ZM379.44 79.338L376.088 64.7465C375.497 61.6573 375.102 59.061 374.905 56.9578H372.243C371.849 60.0469 371.454 62.6432 371.06 64.7465L367.708 79.338H379.44Z" />
      <path d="M415.29 109.014V40.9859C426.858 40.3286 436.553 40 444.374 40C452.459 40 458.703 41.0188 463.107 43.0563C467.576 45.0282 470.863 48.5446 472.966 53.6056C475.069 58.6009 476.121 65.7324 476.121 75C476.121 84.2676 475.069 91.4319 472.966 96.493C470.863 101.488 467.576 105.005 463.107 107.042C458.703 109.014 452.459 110 444.374 110C434.515 110 424.82 109.671 415.29 109.014ZM436.684 94.4225L444.374 94.5211C448.055 94.5211 450.487 93.2394 451.67 90.6761C452.919 88.0469 453.543 82.8216 453.543 75C453.543 67.1784 452.919 61.9859 451.67 59.4225C450.487 56.7934 448.055 55.4789 444.374 55.4789H436.684V94.4225Z" />
      <path d="M524.466 94.3239H505.832L502.382 109.014H480.593L498.241 46.507C498.701 44.8639 499.588 43.5493 500.903 42.5634C502.217 41.5117 503.729 40.9859 505.438 40.9859H524.861C526.569 40.9859 528.081 41.5117 529.396 42.5634C530.71 43.5493 531.598 44.8639 532.058 46.507L549.706 109.014H527.917L524.466 94.3239ZM521.015 79.338L517.663 64.7465C517.072 61.6573 516.677 59.061 516.48 56.9578H513.818C513.424 60.0469 513.03 62.6432 512.635 64.7465L509.283 79.338H521.015Z" />
    </Icon>
  );
};

import * as React from "react";
import styled from "styled-components/macro";
import { FallbackProps } from "react-error-boundary";
import { VFileMessage } from "vfile-message";

export const ErrorFallback: React.FC<
  Omit<FallbackProps, "error"> & { error: Error }
> = ({ resetErrorBoundary, error }) => {
  return (
    <ErrorFallbackContainer role="alert">
      <p>{error.message}:</p>
      <pre>{JSON.stringify(error, null, 2)}</pre>
      <RetryButton type="button" onClick={resetErrorBoundary}>
        Retry
      </RetryButton>
    </ErrorFallbackContainer>
  );
};

export const VfileErrorFallback: React.FC<{
  error: Error;
}> = ({ error }) => {
  const message = new VFileMessage(error.toString());
  message.fatal = true;
  return (
    <ErrorFallbackContainer role="alert">
      <pre>
        {/* eslint-disable-next-line @typescript-eslint/no-base-to-string */}
        <code>{String(message)}</code>
      </pre>
    </ErrorFallbackContainer>
  );
};

export const VfileStatisticsFallback: React.FC<{
  error: string | Error;
}> = ({ error }) => (
  <ErrorFallbackContainer role="alert">
    <pre>
      <code>{String(error)}</code>
    </pre>
  </ErrorFallbackContainer>
);

export const ErrorFallbackContainer = styled.div`
  --shadow-color: 0deg 0% 63%;
  --error-color: #f71033;
  --color-gray-500: hsl(210deg, 8%, 50%);
  padding: 1rem;
  border-radius: 6px;
  background-color: white;
  color: black;
  border-top: 4px solid var(--error-color);
  box-shadow:
    0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
    1.5px 2.9px 3.7px -0.4px hsl(var(--shadow-color) / 0.34),
    2.7px 5.4px 6.8px -0.7px hsl(var(--shadow-color) / 0.34),
    4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / 0.34),
    7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / 0.34),
    11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / 0.34),
    17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / 0.34),
    25px 50px 62.9px -2.5px hsl(var(--shadow-color) / 0.34);
  p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: var(--error-color);
  }
  pre {
    font-family: monospace;
    font-size: 0.9rem;
    white-space: pre-wrap;
  }
`;

const RetryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin-top: 1rem;
  font-size: 0.875rem;
  border: 2px solid;
  border-radius: 4px;
  color: white;
  background-color: var(--color-gray-500);
  cursor: pointer;
`;

import * as React from "react";
import { reporter } from "vfile-reporter";
import { statistics, Statistics } from "vfile-statistics";
import { ErrorBoundary } from "react-error-boundary";
import type { VFile } from "vfile";

import * as UI from "web-app/components/mdx-elements";
import { NotionContainer } from "web-app/notions";

import { useMdx } from "../../hooks/use-mdx";
import {
  ErrorFallback,
  VfileErrorFallback,
  VfileStatisticsFallback,
} from "../../error-boundary";

export const MDXWidgetPreview: React.FC<{ value: string }> = (props) => {
  const { value } = props;
  const [mdxState, setMdxState] = useMdx({
    value,
  });
  const mdxSetter = React.useRef(setMdxState).current;

  //
  const mdxProps = React.useMemo(
    () => ({
      components: { ...UI },
    }),
    [],
  );

  React.useEffect(() => {
    void mdxSetter({ value });
  }, [mdxSetter, value]);

  const stats = mdxState.file
    ? statistics(mdxState.file as never)
    : ({} as Partial<Statistics>);

  return (
    <NotionContainer id="notion-preview">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {mdxState?.file?.result ? (
          <Preview mdxProps={mdxProps} mdxState={mdxState} />
        ) : null}
        {stats?.fatal ? (
          <VfileStatisticsFallback error={reporter(mdxState.file as never)} />
        ) : null}
      </ErrorBoundary>
    </NotionContainer>
  );
};

// Create a preview component that can handle errors with try-catch block
// for catching invalid JS expressions errors that ErrorBoundary cannot catch.
const Preview: React.FC<{
  mdxProps: { components: typeof UI };
  mdxState: {
    value: string;
    file: VFile | null;
  };
}> = (props) => {
  const { mdxProps, mdxState } = props;
  try {
    const preview = (
      mdxState.file?.result as (props: unknown) => React.ReactNode
    )?.(mdxProps);
    return <React.Fragment>{preview}</React.Fragment>;
  } catch (error) {
    return <VfileErrorFallback error={error as Error} />;
  }
};

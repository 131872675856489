import * as React from "react";
import styled from "styled-components/macro";
import { YouTube as MdxYouTube } from "mdx-embed";
import { IYouTubeProps } from "mdx-embed/dist/types/components/youtube/youtube";

export const YouTube: React.FC<IYouTubeProps> = (props) => (
  <YouTubeStyle>
    <MdxYouTube {...props} />
  </YouTubeStyle>
);

const YouTubeStyle = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 0 1.5rem;
  border-radius: 6px;
  background-color: rgba(194, 194, 254, 0.2);
`;

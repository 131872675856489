import * as React from "react";
import CodeMirror, { CodeMirrorProps } from "rodemirror";
import { basicSetup } from "codemirror";
import { markdown as langMarkdown } from "@codemirror/lang-markdown";
import { oneDark } from "@codemirror/theme-one-dark";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useDebounceFn } from "ahooks";
import { CmsWidgetControlProps } from "netlify-cms-core";

type MDXWidgetControlProps = CmsWidgetControlProps<string>;

const ErrorFallback: React.FC<
  Omit<FallbackProps, "error"> & { error: Error }
> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
};

export const MemoizedCodeMirror = React.memo<CodeMirrorProps>(
  function MemoizedCodeMirror(props) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CodeMirror {...props} />
      </ErrorBoundary>
    );
  },
);

export const MDXWidgetControl: React.FC<MDXWidgetControlProps> = (props) => {
  console.log("🚀 ~ file: MDXWidgetControl ~ line 38 ~ props", { props });
  const { value, onChange: onChangeProp } = props;
  const extensions = React.useMemo(
    () => [basicSetup, oneDark, langMarkdown()],
    [],
  );

  const { run } = useDebounceFn(
    (newValue: string) => {
      onChangeProp(newValue);
    },
    {
      wait: 600,
    },
  );

  const onUpdate = React.useCallback<NonNullable<CodeMirrorProps["onUpdate"]>>(
    (v) => {
      if (v.docChanged) {
        run(String(v.state.doc));
      }
    },
    [run],
  );

  return (
    <div id="notion-control">
      <MemoizedCodeMirror
        value={value}
        extensions={extensions}
        onUpdate={onUpdate}
      />
    </div>
  );
};

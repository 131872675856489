import * as React from "react";
import styled from "styled-components/macro";
import { AnimatePresence, motion } from "framer-motion";
import { RiClipboardFill } from "react-icons/ri";
import { IconButton } from "./card-buttons";

export const CopyButton: React.FC<{ value?: string }> = ({ value }) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    const id = setTimeout(() => setCopied(false), 1500);
    return () => clearTimeout(id);
  }, [copied]);

  return (
    <CopyButtonStyle
      disabled={copied || !value}
      onClick={() => {
        navigator.clipboard
          .writeText(value!)
          .then(() => {
            setCopied(true);
            return;
          })
          .catch(console.error);
      }}
    >
      <RiClipboardFill />
      <AnimatePresence>
        {copied ? (
          <CopiedTag
            initial={{ opacity: 0, y: 0, scale: 0.9 }}
            animate={{
              opacity: 1,
              y: -20,
              scale: 1,
              transition: { type: "spring", stiffness: 700, damping: 80 },
            }}
            exit={{
              opacity: 0,
              y: 0,
              scale: 0.9,
              transition: { type: "spring", stiffness: 700, damping: 80 },
            }}
          >
            Copied!
          </CopiedTag>
        ) : null}
      </AnimatePresence>
    </CopyButtonStyle>
  );
};

const CopiedTag = styled(motion.span)`
  position: absolute;
  top: -1.2rem;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.022rem;
  text-align: center;
  line-height: 1;
`;

const CopyButtonStyle = styled(IconButton)`
  position: relative;
  background-color: hsl(223deg 41% 93%);
`;

import * as netlifyIdentity from "netlify-identity-widget";

declare global {
  interface Window {
    netlifyIdentity: typeof netlifyIdentity;
  }
}

// You must run this once before trying to interact with the widget
netlifyIdentity.init({
  APIUrl: "https://cms-foe2e4cskmn.netlify.app/.netlify/identity",
});

netlifyIdentity.on("login", () => {
  window.location.reload();
});

netlifyIdentity.on("logout", () => {
  window.location.reload();
});

window.netlifyIdentity = netlifyIdentity;

import type { CmsConfig } from "netlify-cms-core";

export default {
  backend: {
    name: "git-gateway",
    branch: "master",
    identity_url: "https://cms-foe2e4cskmn.netlify.app/.netlify/identity",
    gateway_url: "https://cms-foe2e4cskmn.netlify.app/.netlify/git",
    commit_messages: {
      create: "Create {{collection}} “{{slug}}”",
      update: "Update {{collection}} “{{slug}}”",
      delete: "Delete {{collection}} “{{slug}}”",
    },
  },
  publish_mode: "editorial_workflow",
  media_library: {
    name: "disabled",
  },
  site_domain: "natanada.com",
  site_url: "https://web-staging.natanada.com/studio",
  logo_url: "/assets/favicon-32x32.png",
  show_preview_links: false,
  slug: {
    encoding: "ascii",
    clean_accents: true,
    sanitize_replacement: "-",
  },
  collections: [
    {
      name: "notion",
      label: "Notions",
      folder: "apps/web/src/notions",
      create: true,
      slug: "{{id}}",
      extension: "mdx",
      widget: "mdx",
      format: "frontmatter",
      fields: [
        {
          label: "Template Key",
          name: "templateKey",
          widget: "hidden",
          default: "notion",
        },
        {
          label: "ID",
          name: "id",
          widget: "id",
        },
        {
          label: "Title",
          name: "title",
          widget: "string",
        },
        {
          label: "Publish Date",
          name: "date",
          widget: "datetime",
        },
        {
          label: "",
          name: "",
          widget: "content-upload-link",
        },
        {
          label: "Body",
          name: "body",
          widget: "mdx",
        },
      ],
    },
  ],
} as unknown as CmsConfig;
